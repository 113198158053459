// helper functions
// Can be imported to any controller or other files
// User `import { debounce } from '../helpers/functions';` to import functions


// Debounce function to limit the rate of function execution
// Can we called from stimulus like: debounce(this.submit.bind(this), 300);
export function debounce(func, wait) {
  let timeout;
  let lastCallTime = 0;

  return function(...args) {
    const now = Date.now();

    if (now - lastCallTime >= wait) {
      lastCallTime = now;
      func.apply(this, args);
    } else {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        lastCallTime = Date.now();
        func.apply(this, args);
      }, wait - (now - lastCallTime));
    }
  };
}

// Get the locale from the metadata or return "en" if it's missing
export function getLocale() {
  const locale = document.querySelector('meta[name="locale"]')?.content;
  return locale || "en";
}

export function getDateFormat(){
  return document.querySelector('meta[name="date-format"]')?.content;
}

export function getDateTimeFormat(){
  return document.querySelector('meta[name="date-time-format"]')?.content;
}

export function isObject(value) {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
}
