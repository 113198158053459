import { Controller } from '@hotwired/stimulus';
import { debounce } from '../helpers/functions';

// Searchable controller is a controller to allow for dynamic searchable content
// It helps with submitting the form automatically on change
// It helps with show the sortable elements correctly
// It helps with Managing the filters
// It helps with Pagination
export default class extends Controller {
  static targets = [
    'filters',
    'search',
    'pagination'
  ];

  static values = {
    query: String
  };

  connect() {
    this.updateQuery();
    this.submit = debounce(this.submit.bind(this), 500); // Debounce the submit method
  }

  // Give all the pagination links a qc-btn class to simplify styling
  paginationTargetConnected(element) {
    element.querySelectorAll('a').forEach((link) => {
      link.classList.add('qc-btn');
      // If it has an attribute aria-label then add class qc-btn-primary
      if (link.getAttribute('aria-label')) {
        link.classList.add('qc-btn-primary');
      } else {
        link.classList.add('qc-btn-secondary');
      }
      if (link.classList.contains('current')) {
        link.classList.add('active');
      }
      // If it contains attribute aria-disabled add class disabled
      if (link.getAttribute('aria-disabled') === 'true') {
        link.classList.add('disabled');
      }
    });
  }

  updateQuery() {
    let formData = new FormData(this.element);
    this.queryValue = new URLSearchParams(formData).toString();
    return this.queryValue;
  }

  submit() {
    if (this.queryValue !== this.updateQuery()) {
      this.element.requestSubmit();
    } else {
      console.log('No change in query');
    }
  }
}
