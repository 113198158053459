import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    fieldNames: Array
  };

  connect() {
  }

  clear() {
    // Build a query to select all the potential fields that need to be cleared
    let querySelector = this.fieldNamesValue.map((paramName) => this.buildQuerySelector(paramName)).join(', ');
    // Use the query to select all potential fields and clear them
    document.querySelectorAll(querySelector).forEach((field) => this.clearField(field));
    // Auto-submit the form to apply the changes
    this.element.closest('form').requestSubmit();
  }

  buildQuerySelector(paramName) {
    let querySelectors = [];
    // Find all input fields with the name of paramName
    querySelectors.push(`input[name="${paramName}"]`);
    // Find al single select fields
    querySelectors.push(`select[name="${paramName}"]`);
    // Find all options of a multiple select field
    let selectFieldName = paramName.split('[')[0];
    let optionValue = paramName.split('[').pop().slice(0, -1);
    querySelectors.push(`select[name="${selectFieldName}[]"] option[value="${optionValue}"]`);

    // Return the querySelectors as comma separated string
    return querySelectors.join(', ');
  }

  clearField(field) {
    // Check the type of the field
    switch (field.type) {
      // Checkbox or Radio fields
      case 'checkbox':
      case 'radio':
        field.checked = false;
        break;
      default:
        switch (field.tagName) {
          case 'OPTION':
            // Remove method for Choices.js
            field.dispatchEvent(new CustomEvent('removeItem', { bubbles: true, detail: { value: field.value } }));
            field.remove();
            // Default deselect process
            field.selected = false;
            field.dispatchEvent(new Event('change', { bubbles: true }));
            break;
          case 'SELECT':
            field.selectedIndex = 0;
            break;
          default:
            field.value = '';
        }
    }
  }
}
